import Vuex from "vuex";
import axios from "axios";
import { Device } from "@capacitor/device";
import { App } from "@capacitor/app";
import { Preferences } from "@capacitor/preferences";
import { isPlatform } from "@ionic/vue";
import {
  setUserPropertyDeviceId,
  logWineListViewEvent,
  logError,
} from "@/lib/analytics";
import { getViewedStories } from "@/lib/stories";
import config from "@/app.config";
import {
  getSavedFilters,
  getFiltersValuesFromQuery,
  createSearchParams,
} from "@/lib/filters";
import location from "./location";
import shops from "./shops";
import favorites from "./favorites";

const CancelToken = axios.CancelToken;
let requestInProgress = null;

export default Vuex.createStore({
  modules: {
    location,
    shops,
    favorites,
  },
  state: {
    wines: [],
    winesLoading: false,
    winesLoadingError: false,
    winesRegionNotAvailable: false,
    winesPage: 1,
    currentWine: null,
    currentWineLoading: false,
    currentWineLoadingError: false,
    totalWines: 0,
    stories: [],
    storiesLoading: true,
    filters: null,
    filtersLoading: false,
    filtersValues: {},
    searchParams: {}, // TODO: What for?
    config: {},
    isAgeConfirmed: false,
    isStartScreen: true,
    appVersion: null,
    uuid: null,
    locale: null,
    currency: "",
  },
  mutations: {
    addWines(state, wines) {
      state.wines = state.wines.concat(wines);
    },
    resetWines(state) {
      state.wines = [];
    },
    setWinesLoading(state, winesLoading) {
      state.winesLoading = winesLoading;
    },
    setWinesLoadingError(state, winesLoadingError) {
      state.winesLoadingError = winesLoadingError;
    },
    setWinesRegionNotAvailable(state, winesRegionNotAvailable) {
      state.winesRegionNotAvailable = winesRegionNotAvailable;
    },
    incrementWinesPage(state) {
      state.winesPage++;
    },
    setCurrentWine(state, currentWine) {
      state.currentWine = currentWine;
    },
    setCurrentWineLoading(state, currentWineLoading) {
      state.currentWineLoading = currentWineLoading;
    },
    setCurrentWineLoadingError(state, currentWineLoadingError) {
      state.currentWineLoadingError = currentWineLoadingError;
    },
    setTotalWines(state, total) {
      state.totalWines = total;
    },
    setStories(state, stories) {
      state.stories = stories;
    },
    setStoriesLoading(state, storiesLoading) {
      state.storiesLoading = storiesLoading;
    },
    setFilters(state, filters) {
      state.filters = filters;
    },
    setFiltersLoading(state, filtersLoading) {
      state.filtersLoading = filtersLoading;
    },
    setFiltersValues(state, filtersValues) {
      state.filtersValues = filtersValues;
    },
    setSearchParams(state, searchParams) {
      state.searchParams = searchParams;
    },
    setConfig(state, config) {
      state.config = config;
    },
    setAgeConfirmed(state, isAgeConfirmed) {
      state.isAgeConfirmed = isAgeConfirmed;
    },
    setSkipStartScreen(state, skipStartScreen) {
      state.skipStartScreen = skipStartScreen;
    },
    setAppVersion(state, appVersion) {
      state.appVersion = appVersion;
    },
    setUuid(state, uuid) {
      state.uuid = uuid;
    },
    setLocale(state, locale) {
      state.locale = locale;
    },
    setCurrency(state, currency) {
      state.currency = currency;
    },
  },
  actions: {
    /**
     * Init app
     */
    async init({ commit, dispatch }, { locale, query }) {
      let uuid = config.defaultUuid;
      let appVersion = "";
      let appPlatform = "";

      if (isPlatform("capacitor")) {
        const deviceId = await Device.getId();
        const info = await App.getInfo();

        uuid = deviceId.identifier;
        appVersion = info.version;
        appPlatform = isPlatform("ios") ? "ios" : "android";
      }

      setUserPropertyDeviceId(uuid);

      commit("setLocale", locale);
      commit("setAppVersion", appVersion);
      commit("setUuid", uuid);

      axios.defaults.headers.common = {
        "X-App-Instance-Id": uuid,
        "X-App-Version": appVersion,
        "X-App-Platform": appPlatform,
        "X-App-Locale": locale,
      };

      dispatch("fetchConfig");
      dispatch("initFilterValues", query);
    },
    /**
     * Set default values for filters
     */
    initFilterValues({ commit, state }, query) {
      let values = {
        price: {
          from: 1,
          to: 3000,
        },
        wineType: 0,
        fooddata: [],
        shop: 0,
        shopChain: 0,
        blend: 0,
        country: 0,
        tag: "",
        discount: null,
        sugar: 0,
      };

      // Override default values with saved
      const saved = getSavedFilters();
      if (
        saved.price &&
        saved.price.currency?.toLowerCase() === state.currency.toLowerCase()
      ) {
        values.price = saved.price;
      }

      // Override with query params
      values = { ...values, ...getFiltersValuesFromQuery(query) };

      commit("setFiltersValues", values);
    },
    /**
     * Load app config for current native client
     */
    async fetchConfig({ commit, state }) {
      if (state.uuid) {
        try {
          const result = await axios.get(
            `${config.apiHost}/pairing/getConfig.ashx`,
          );
          commit("setConfig", result.data);
          console.log("set config: " + JSON.stringify(result.data));
        } catch (e) {
          console.warn("Config not loaded", e);
        }
      }
    },
    /**
     * Guess currency by country
     */
    async setCurrencyByCountry({ commit }, countryCode) {
      const currency = countryCode === "RU" ? "RUB" : "EUR";
      commit("setCurrency", currency);

      axios.defaults.headers.common = {
        ...axios.defaults.headers.common,
        "X-App-Currency": currency,
      };
    },
    /**
     * Get list of wines by filters with pagination
     */
    async fetchWines({ commit, state }, { filters, page, sort }) {
      if (page === 1) {
        commit("resetWines");
      }

      const searchParams = createSearchParams(state, filters, page, sort);
      commit("setSearchParams", searchParams);
      commit("setWinesLoading", true);
      commit("setWinesLoadingError", false);
      try {
        if (requestInProgress) {
          requestInProgress.cancel();
        }
        requestInProgress = CancelToken.source();
        const result = await axios.post(
          `${config.apiHost}/pairing/getwines3.ashx`,
          JSON.stringify(searchParams),
          {
            cancelToken: requestInProgress.token,
          },
        );
        if (result.data.page) {
          commit("addWines", result.data.page);
          logWineListViewEvent(result.data.page, "Nearby", page);
        }
        commit("setTotalWines", result.data.total);
        commit("setWinesRegionNotAvailable", !!result.data.regionNotAvailable);
        commit("setWinesLoading", false);
      } catch (e) {
        if (!axios.isCancel(e)) {
          commit("setWinesLoadingError", true);
          commit("setWinesLoading", false);
          logError(e);
          console.log(e.response.data);
          throw e;
        }
      }
    },
    /**
     * Get single wine by id
     */
    async fetchWineById({ commit, state }, id) {
      const searchParams = createSearchParams(state, { wineId: id }, 1);
      const result = await axios.post(
        `${config.apiHost}/pairing/getwines3.ashx`,
        JSON.stringify(searchParams),
      );
      if (result.data.page) {
        commit("setCurrentWine", result.data.page[0]);
      }
    },
    /**
     * Get all stories and move viewed stories to the end
     */
    async fetchStories({ commit }) {
      const result = await axios.get(
        `${config.apiHost}/pairing/getStories.ashx`,
      );

      const viewedStories = getViewedStories();
      const stories = [...result.data];
      if (stories.length) {
        viewedStories.forEach((id) => {
          const index = stories.findIndex((story) => story.id === id);
          const elements = stories.splice(index, 1);
          stories.push(elements[0]);
        });
      }

      commit("setStories", stories);
      commit("setStoriesLoading", false);
    },
    /**
     * Get all filters
     */
    async fetchFilters({ state, commit, dispatch }) {
      if (state.filtersLoading) {
        return;
      }
      commit("setFiltersLoading", true);
      const result = await axios.get(
        `${config.apiHost}/pairing/getFilters.ashx`,
        {
          params: {
            latitude: state.location.location.latitude,
            longitude: state.location.location.longitude,
          },
        },
      );
      commit("setFilters", result.data[0]);
      commit("setFiltersLoading", false);

      dispatch("shops/fetchShops", state.filtersValues.shopChain);
    },
    /**
     * Fetch user age confimation from store
     */
    async fetchAgeConfirmed({ commit }) {
      const { value } = await Preferences.get({ key: "isAgeConfirmed" });
      commit("setAgeConfirmed", value === "yes");
    },
    /**
     * Fetch start screen flag from store
     */
    async fetchSkipStartScreen({ commit }) {
      const { value } = await Preferences.get({ key: "skipStartScreen" });
      commit("setSkipStartScreen", value === "yes");
    },
    /**
     * Store user age confirmation
     */
    async confirmAge({ commit }) {
      await Preferences.set({
        key: "isAgeConfirmed",
        value: "yes",
      });
      commit("setAgeConfirmed", true);
    },
    /**
     * Store start screen flag
     */
    async skipStartScreen({ commit }) {
      await Preferences.set({
        key: "skipStartScreen",
        value: "yes",
      });
      commit("setSkipStartScreen", true);
    },
  },
});
