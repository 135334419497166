<template>
  <ion-page>
    <ion-content
      class="ion-content search-page-content ion-text-left"
      :scroll-events="true"
      ref="content"
      @ionScrollStart="onScroll"
    >
      <div slot="fixed" class="searchbar-container">
        <ion-searchbar
          class="searchbar"
          :placeholder="$t('search.searchbarPlaceholder')"
          :debounce="debounceTime"
          ref="searchbar"
          mode="md"
          style="--box-shadow: 0"
          :cancel-button-text="$t('search.searchbarCancel')"
          show-cancel-button="always"
          :cancel-button-icon="arrowBackOutline"
          enterkeyhint="search"
          :value="query"
          @ionChange="onSearch"
          @ionCancel="onCancel"
        />
      </div>
      <ion-grid fixed class="about-grid about-grid-content">
        <ion-list
          v-if="!isLoading && !isError && !wines.length && !isQuery"
          lines="none"
          mode="md"
        >
          <div v-if="searchHistoryItems.length" style="margin-bottom: 20px">
            <ion-list-header mode="md">
              <ion-label>История поиска</ion-label>
            </ion-list-header>

            <ion-item
              v-for="item in searchHistoryItems"
              :key="item"
              button
              @click="setQuery(item)"
            >
              <ion-label class="ion-text-wrap">
                {{ item }}
              </ion-label>
              <ion-icon
                :icon="historyIcon"
                slot="start"
                size="small"
              ></ion-icon>
            </ion-item>
          </div>

          <ion-list-header mode="md">
            <ion-label>Популярные запросы</ion-label>
          </ion-list-header>
          <ion-item
            v-for="item in popularItems"
            :key="item"
            button
            @click="setQuery(item)"
          >
            <ion-label class="ion-text-wrap">
              {{ item }}
            </ion-label>
            <ion-icon :icon="searchIcon" slot="start" size="small"></ion-icon>
          </ion-item>
        </ion-list>

        <wine-list
          v-if="isQuery"
          :key="query"
          :wines="wines"
          :total="total"
          :is-loading="isLoading"
          :is-error="isError"
          @fetch="fetchWines"
          @fetch-more="fetchMoreWines"
        />
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonContent,
  IonToolbar,
  IonGrid,
  IonSearchbar,
  IonList,
  IonItem,
  IonIcon,
  IonLabel,
  IonListHeader,
} from "@ionic/vue";
import { arrowBackOutline, timeOutline } from "ionicons/icons";
import axios from "axios";
import { search } from "ionicons/icons";

import WineList from "@/components/WineList.vue";
import config from "@/app.config";
import {
  logScreenViewEvent,
  logSearchEvent,
  logWineListViewEvent,
  logError,
} from "@/lib/analytics";
import { getSearchHistory, addToSearchHistory } from "@/lib/searchHistory";

const CancelToken = axios.CancelToken;

export default {
  components: {
    IonPage,
    IonHeader,
    IonContent,
    IonToolbar,
    IonGrid,
    IonSearchbar,
    IonIcon,
    IonList,
    IonItem,
    IonLabel,
    IonListHeader,
    WineList,
  },
  data() {
    return {
      arrowBackOutline,
      historyIcon: timeOutline,
      query: "",
      isLoading: false,
      isError: false,
      wines: [],
      searchIcon: search,
      regionNotAvailable: false,
      page: 1,
      total: 0,
      debounceTime: 500,
      requestInProgress: false,
      searchHistoryItems: [],
      popularItems: ["Cabernet Sauvignon", "Шампанское", "Merlot", "Бароло"],
    };
  },
  computed: {
    isQuery() {
      return this.query.length > 3;
    },
  },
  async mounted() {
    logScreenViewEvent("search");
    setTimeout(() => {
      this.$refs.searchbar.$el.setFocus();
    }, 500);
    this.fetchSearchHistory();
  },
  watch: {
    query(newVal) {
      if (newVal.length === 0) {
        this.wines = [];
      }
    },
  },
  methods: {
    setQuery(query) {
      this.isLoading = true;
      this.query = query;
    },
    onSearch(event) {
      this.query = event.detail.value;
      if (this.isQuery) {
        if (
          this.query &&
          this.query.length &&
          !this.searchHistoryItems.includes(this.query)
        ) {
          addToSearchHistory("wines", this.query);
          this.fetchSearchHistory();
        }
        this.scrollToTop();
        this.search();
      }
    },
    async onScroll() {
      const element = await this.$refs.searchbar.$el.getInputElement();
      element.blur();
    },
    onCancel() {
      return this.$router.back();
    },
    scrollToTop() {
      if (this.$refs.content) {
        this.$refs.content.$el.scrollToTop(0);
      }
    },
    async fetchSearchHistory() {
      this.searchHistoryItems = (await getSearchHistory("wines")).reverse();
    },
    async search() {
      this.wines = [];
      this.page = 1;
      this.regionNotAvailable = false;
      this.fetchWines();
      logSearchEvent(this.query);
    },
    async fetchWines() {
      this.isError = false;
      try {
        this.isLoading = true;
        if (this.requestInProgress) {
          this.requestInProgress.cancel();
        }
        this.requestInProgress = CancelToken.source();

        const searchParams = {
          page: this.page,
          searchTerm: this.query,
          location: {
            Latitude: this.$store.state.location.location.latitude,
            Longitude: this.$store.state.location.location.longitude,
          },
        };
        const { data } = await axios.post(
          `${config.apiHost}/pairing/searchWines3.ashx`,
          JSON.stringify(searchParams),
          {
            cancelToken: this.requestInProgress.token,
          },
        );
        if (data.page) {
          this.wines = this.wines.concat(data.page);
          this.total = data.total;
          logWineListViewEvent(this.wines, "Search", this.page);
        } else {
          this.total = 0;
        }
        this.regionNotAvailable = data.regionNotAvailable;
        this.isLoading = false;
      } catch (e) {
        if (!axios.isCancel(e)) {
          this.isError = true;
          this.isLoading = false;
          logError(e);
          throw e;
        }
      }
    },
    async fetchMoreWines(event) {
      if (this.isQuery) {
        this.page++;
        await this.fetchWines();
      }
      //event.target.complete();
    },
  },
};
</script>

<style lang="scss" scoped>
ion-list {
  padding: 0 10px;
}
ion-list-header {
  text-transform: none;
  font-size: 0.8em;
}
.search-page-content {
  --searchbar-height: 40px;
}
.about-grid {
  margin-top: 20px;
  --ion-grid-width: 800px;
}
.about-grid-content {
  height: 100%;
  padding-top: calc(var(--searchbar-height) + var(--ion-safe-area-top) + 18px);
  padding-left: 0;
  padding-right: 0;
}
.centered-container {
  margin-top: calc(50% - 50px);
  text-align: center;
}
.searchbar-container {
  width: 100vw;
  max-width: 800px;
  background-color: var(--ion-toolbar-background);
  padding: 10px 8px;
  padding-top: calc(var(--ion-safe-area-top) + 10px);
  display: flex;
}
.cancel-button {
  height: 2em;
  --padding-start: 0;
  --padding-end: 10px;
}
@media only screen and (min-width: 800px) {
  .searchbar-container {
    left: calc((100vw - 800px) / 2);
  }
}
.searchbar {
  height: var(--searchbar-height);

  :deep(.searchbar-input) {
    font-weight: 600;
    caret-color: var(--ion-color-primary);
  }
}
</style>
