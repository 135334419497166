import { createAnimation } from "@ionic/vue";

export const materialPageTransition = (router, opts) => {
  const { enteringEl, leavingEl, direction } = opts;

  const translateXLeavingPage = direction === "forward" ? "-50px" : "50px";
  const translateXEnteringPage = direction === "forward" ? "50px" : "-50px";

  const animationLeave = createAnimation()
    .addElement(leavingEl)
    .fromTo("opacity", "1", "0")
    .fromTo(
      "transform",
      "translateX(0)",
      `translateX(${translateXLeavingPage})`,
    )
    // Standard accelerate
    .easing("cubic-bezier(0.4, 0, 1, 1)")
    .duration(150)
    .delay(50);

  const animationEnter = createAnimation()
    .addElement(enteringEl)
    .fromTo("opacity", "0", "1")
    .fromTo(
      "transform",
      `translateX(${translateXEnteringPage})`,
      "translateX(0)",
    )
    // Standard decelerate
    .easing("cubic-bezier(0, 0, 0.2, 1)")
    .duration(200)
    .delay(150);

  return createAnimation()
    .addAnimation(animationEnter)
    .addAnimation(animationLeave);
};
