import { createApp } from "vue";
import Application from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";

import { createI18n } from "vue-i18n";
import { isPlatform } from "@ionic/vue";
import { Device } from "@capacitor/device";
import ruPluralizationRule from "./lib/locale";
import { materialPageTransition } from "./lib/materialPageTransition";
import messages from "./messages";

import { IonicVue } from "@ionic/vue";
import { App } from "@capacitor/app";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";

import { polyfill } from "seamless-scroll-polyfill";
polyfill();

const app = createApp(Application)
  .use(IonicVue, {
    navAnimation:
      isPlatform("ios") && isPlatform("capacitor")
        ? undefined
        : materialPageTransition,
  })
  .use(VueAxios, axios)
  .use(router)
  .use(store);

/**
 * Age Confirmation Screen
 */
router.beforeEach(async (to) => {
  if (!store.state.skipStartScreen) {
    await store.dispatch("fetchSkipStartScreen");
  }
  if (!store.state.skipStartScreen && to.name !== "StartScreen") {
    return "/start";
  }
});

/**
 * Deep Links
 */
App.addListener("appUrlOpen", function (data) {
  const path = data.url.split("https://app.wineradar.ru").pop();
  if (path) {
    router.push(path);
  }
});

/**
 * Internationlization
 */
const isNativeApp = isPlatform("capacitor");
const useI18n = (locale) => {
  const i18n = createI18n({
    locale, // set locale
    fallbackLocale: "en", // set fallback locale
    pluralizationRules: {
      ru: ruPluralizationRule,
    },
    messages,
  });
  app.use(i18n);
};

/**
 * Init app
 */
(async () => {
  if (isNativeApp) {
    const languageCode = await Device.getLanguageCode();
    //useI18n(languageCode.value);
    useI18n("ru");
  } else {
    const params = new URLSearchParams(window.location.search);
    useI18n(params.get("locale") || "ru");
  }

  await router.isReady();
  app.mount("#app");
})();
