<template>
  <div>
    <div v-if="showGeolocationEvents">
      <div v-for="event in events" :key="event">
        <small>{{ event }}</small>
      </div>
    </div>
    <div>
      <div v-if="!address">
        <div v-if="isLocationBlocked && !address" class="location-error">
          {{ $t("location.geolocationDisabledInSettings") }}
        </div>
        <div v-if="locationError && !address" class="location-error">
          {{ $t("location.geolocationGeneralError") }}
        </div>
        <div v-if="isLocationBlocked || locationError">
          <ion-button
            v-if="!locationLoading"
            mode="ios"
            size=""
            fill="clear"
            class="change-button"
            style="font-weight: 500"
            @click="openLocationModal"
          >
            {{ $t("location.enterAddressButton") }}
          </ion-button>
        </div>
      </div>

      <div v-else class="address">
        <ion-button
          mode="ios"
          fill="clear"
          color="dark"
          style="font-weight: 500"
          class="change-button ion-text-wrap ion-text-start"
          @click="openLocationModal"
        >
          <ion-spinner
            v-if="locationLoading"
            color="dark"
            name="lines-small"
          ></ion-spinner>
          <ion-icon
            v-else
            :icon="locationIcon"
            class="location-icon"
          ></ion-icon>
          <div v-if="address" style="padding-left: 5px">
            {{ address }}
          </div>
          <ion-icon
            v-if="!locationLoading"
            slot="end"
            :icon="changeIcon"
            color="primary"
            style="font-size: 18px"
          ></ion-icon>
        </ion-button>
      </div>
    </div>
  </div>
</template>

<script>
import { IonButton, IonIcon, IonSpinner, modalController } from "@ionic/vue";
import {
  locationOutline,
  chevronForwardOutline,
  chevronDownOutline,
} from "ionicons/icons";
import { mapState } from "vuex";
import ModalLocation from "@/components/ModalLocation.vue";

export default {
  components: {
    IonButton,
    IonIcon,
    IonSpinner,
  },
  emits: ["select"],
  props: {
    value: {
      type: Array,
    },
    isLocationBlocked: {
      type: Boolean,
    },
  },
  data() {
    return {
      locationIcon: locationOutline,
      changeIcon: chevronForwardOutline,
    };
  },
  methods: {
    async openLocationModal() {
      const modal = await modalController.create({
        component: ModalLocation,
        componentProps: {
          address: this.location.fullAddress,
        },
        cssClass: "custom-modal",
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        this.$store.dispatch("location/setLocationByAutocomplete", data);
        this.$emit("select");
      }
    },
  },
  computed: {
    ...mapState("location", [
      "location",
      "locationLoading",
      "locationError",
      "events",
    ]),
    ...mapState(["filters"]),
    showGeolocationEvents() {
      return localStorage.getItem("showGeolocationEvents") === "true";
    },
    address() {
      if (this.locationLoading) {
        return this.$t("location.addressLoading");
      }
      if (
        this.location &&
        !this.location.shortAddress?.length &&
        !this.isLocationBlocked &&
        !this.locationError
      ) {
        return `${this.location.latitude?.toFixed(
          6,
        )}, ${this.location.longitude?.toFixed(6)}`;
      }
      return this.location?.shortAddress;
    },
    title() {
      return this.$t("location.nearbyHeader");
    },
  },
};
</script>

<style lang="scss" scoped>
.location-icon {
  font-size: 1.3em;
}
.address {
  display: flex;
  min-height: 26px;
  align-items: center;
  font-weight: 500;
}
.location-error {
  font-size: 0.8em;
}
.change-button {
  --padding-start: 0;
  --padding-end: 0;
  font-size: 1em;
}
</style>
