<template>
  <ion-page ref="page" id="home-page">
    <ion-header class="ion-no-border" collapse="fade">
      <div style="height: var(--ion-safe-area-top, 0)"></div>
    </ion-header>

    <ion-content class="ion-text-left home-page-content" ref="content">
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>

      <!-- <ion-header class="ion-no-border ion-padding padding-bottom-0" mode="ios">
        <ion-grid fixed>
          <ion-toolbar>
            <ion-buttons slot="start" class="buttons-collapse">
              <location-selector :is-location-blocked="isLocationBlocked" />
            </ion-buttons>
            <ion-buttons slot="end" class="buttons-collapse">
              <ion-button
                v-if="isNativeApp"
                class="search-button"
                router-link="/favorites"
              >
                <ion-icon :icon="favoritesIcon"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-grid>
      </ion-header> -->

      <ion-grid fixed style="--ion-grid-padding: 0">
        <play-store-link />

        <header
          class="display-flex justify-content-space-between align-items-center ion-padding-horizontal"
          style="padding-bottom: 10px"
        >
          <location-selector
            :is-location-blocked="isLocationBlocked"
            @select="onSetLocation"
          />
          <div class="display-flex align-items-center flex-shrink-0">
            <ion-button
              v-if="true"
              class="circle-button"
              fill="clear"
              router-link="/favorites"
            >
              <ion-icon slot="icon-only" :icon="favoritesIcon"></ion-icon>
            </ion-button>
            <ion-button
              class="circle-button"
              fill="clear"
              router-link="/settings"
            >
              <ion-icon slot="icon-only" :icon="helpIcon"></ion-icon>
            </ion-button>
          </div>
        </header>

        <filter-search @focus="openSearch" />

        <service-message />

        <rate-this-app />

        <update-banner />

        <!-- <stories :stories="stories" /> -->

        <filter-shop
          :value="filtersValues.shop"
          :shop-chain="filtersValues.shopChain"
          @select="setShop"
        />

        <region-not-available
          v-if="winesRegionNotAvailable && wines.length"
          :shop-chain-id="filtersValues.shopChain"
        />

        <filters
          class="ion-padding"
          style="padding-top: 0"
          @select="onFiltersApply"
        />

        <order-selector @change="setSortOrder" class="ion-padding-horizontal" />

        <wine-list
          :wines="wines"
          :is-loading="winesLoading || filtersLoading || locationLoading"
          :is-error="winesLoadingError"
          :total="totalWines || 0"
          @fetch="fetchWines"
          @fetch-more="fetchMoreWines"
        />
      </ion-grid>
    </ion-content>
    <age-confirm />
  </ion-page>
</template>

<script>
/* eslint-disable */
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  isPlatform,
  useBackButton,
} from '@ionic/vue';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { arrowUpOutline, search, heartOutline, helpCircleOutline } from 'ionicons/icons';
import { mapActions, mapState } from 'vuex';

import AgeConfirm from '@/components/AgeConfirm.vue';
import Filters from '@/components/Filters.vue';
import FilterSearch from '@/components/FilterSearch.vue';
import LocationSelector from '@/components/LocationSelector.vue';
import FilterShop from '@/components/FilterShop.vue';
import UpdateBanner from '@/components/UpdateBanner.vue';
//import Stories from '@/components/Stories.vue';
import WineList from '@/components/WineList.vue';
import RegionNotAvailable from '@/components/RegionNotAvailable.vue';
import ServiceMessage from '@/components/ServiceMessage.vue';
import RateThisApp from '@/components/RateThisApp.vue';
import LensButton from '@/components/LensButton.vue';
import OrderSelector from '@/components/OrderSelector.vue';
import PlayStoreLink from '@/components/PlayStoreLink.vue';
import { logScreenViewEvent } from '@/lib/analytics';

export default {
  name: 'Home',
  components: {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonGrid,
    IonButton,
    IonButtons,
    IonCol,
    IonRow,
    IonIcon,
    IonRefresher,
    IonRefresherContent,
    AgeConfirm,
    //Stories,
    LocationSelector,
    FilterShop,
    FilterSearch,
    Filters,
    UpdateBanner,
    RateThisApp,
    RegionNotAvailable,
    ServiceMessage,
    LensButton,
    OrderSelector,
    PlayStoreLink,
    WineList,
  },
  provide() {
    return {
      page: this.pageElement
    }
  },
  data() {
    return {
      arrowUpIcon: arrowUpOutline,
      searchIcon: search,
      favoritesIcon: heartOutline,
      helpIcon: helpCircleOutline,
      isLocationPermissionRequired: false,
      isLocationBlocked: false,
      locationIsDenied: false,
      page: 1,
      pageElement: null,
      sort: 'score',
    }
  },
  watch: {
    $route(to, from) {
      if (from.name === to.name && Object.keys(to.query).length) {
        // TODO: check this
        //this.filtersValues = getFiltersInitialValues({ ...to.query, price: this.filtersValues.price });
        this.page = 1;
        this.fetchWines();
      }
    },
    // location() {
    //   this.onSetLocation();
    // },
    filtersValues(newValues, oldValues) {
      if (Object.keys(oldValues).length !== 0) {
        // If Shop Chain filter is changed we need to show reload shops list
        if (oldValues['shopChain'] !== newValues['shopChain']) {
          this.fetchShops(newValues['shopChain']);
          this.setShop();
        }
      }
    },
  },
  async mounted() {
    this.pageElement = this.$refs.page.$el;

    //await this.$store.dispatch('init', { locale: this.$i18n.locale, query: this.$route.query });

    // Stories are hidden for now
    //this.fetchStories();

    // Moved to App.vue
    console.log('mounted location', this.location);
    if (!this.location) {
      await this.initGeolocation();
    }

    //this.$store.dispatch('favorites/fetch');

    // This is disabled because when location is changed in location selector it triggers fetchWines
    // Maybe we don't need to call onSetLocation on page load, but only when user changed location
    this.fetchFilters(this.$route.query);
    this.fetchWines();

    logScreenViewEvent('home');

    // Scroll to top when back button is pressed
    useBackButton(10, (processNextHandler) => {
      this.scrollToTop();
      processNextHandler();
    });
  },
  computed: {
    ...mapState([
      'wines',
      'winesLoading',
      'winesLoadingError',
      'winesRegionNotAvailable',
      'totalWines',
      'stories',
      'storiesLoading',
      'config',
      'filtersValues',
      'filtersLoading',
    ]),
    ...mapState('location', [
      'location',
      'locationLoading',
      'locationError',
    ]),
    isNativeApp() {
      return isPlatform('capacitor');
    },
  },
  methods: {
    ...mapActions('location', ['checkGeolocation', 'initGeolocation']),
    ...mapActions('shops', ['fetchShops']),
    ...mapActions(['fetchFilters', 'fetchStories']),
    async doRefresh(event) {
      this.fetchStories();
      await this.checkGeolocation();
      await this.fetchFilters(this.$route.query);
      this.fetchWines();
      event.target.complete();
    },
    async onSetLocation() {
      console.log('on set location');
      this.fetchFilters(this.$route.query);
      this.onFiltersApply();
    },
    setShop(shop = 0) {
      this.$store.commit('setFiltersValues', { ...this.filtersValues, shop });
      this.onFiltersApply();
    },
    setSortOrder(sortOrder) {
      this.sort = sortOrder;
      this.page = 1;
      this.fetchWines();
    },
    onFiltersApply() {
      this.page = 1;
      Haptics.impact({ style: ImpactStyle.Light });
      this.fetchWines();
    },
    fetchMoreWines() {
      Haptics.impact({ style: ImpactStyle.Light });
      setTimeout(() => {
        this.page++;
        this.fetchWines();
      }, 200);
    },
    fetchWines() {
      this.$store.dispatch('fetchWines', {
        filters: this.filtersValues,
        page: this.page,
        sort: this.sort,
      });
    },
    async scrollToTop() {
      this.$refs.content.$el.scrollToTop(200);
    },
    openSearch() {
      this.$router.push({
        name: 'Search',
      });
    },
  }
}
</script>
