import { Geolocation } from "@capacitor/geolocation";

import { getAddressByCoords as getAddressByCoordsDaData } from "@/lib/geolocationDaData";
import { getAddressByCoords as getAddressByCoordsMapkit } from "@/lib/geolocationMapkit";

import { searchAddress as searchAddressDaData } from "@/lib/geolocationDaData";
import { searchAddress as searchAddressMapkit } from "@/lib/geolocationMapkit";

const PERMISSION_DENIED = "denied";

export function getCurrentLocation(enableHighAccuracy = true) {
  // return new Promise((resolve, reject) => {
  //   resolve({
  //     coords: {
  //       latitude: 55.755826,
  //       longitude: 37.6173,
  //     },
  //   });
  // });
  return Geolocation.getCurrentPosition({
    enableHighAccuracy,
    maximumAge: 0, // Cache is not used
    timeout: 15000,
  });
}

export function getDefaultLocation() {
  return {
    coords: {
      latitude: 55.755826,
      longitude: 37.6173,
    },
  };
}

export async function getLocationPermissions() {
  try {
    return await Geolocation.checkPermissions();
  } catch (error) {
    console.log("Geolocation is disabled");
    return {
      location: PERMISSION_DENIED,
    };
  }
}

export async function getAddressByCoords(lat, lon, options) {
  return useDaData(options)
    ? getAddressByCoordsDaData(lat, lon)
    : getAddressByCoordsMapkit(lat, lon, options.locale);
}

export async function searchAddress(
  query,
  latitude = 0,
  longitude = 0,
  options,
) {
  return useDaData(options)
    ? searchAddressDaData(query, options.locale, latitude, longitude)
    : searchAddressMapkit(query, options.locale, latitude, longitude);
}

export function useDaData(options) {
  return options.provider ? options.provider === "dadata" : true;
}
