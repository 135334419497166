<template>
  <ion-page>
    <ion-content class="ion-content ion-padding about-page-content">
      <ion-toolbar>
        <ion-buttons slot="start" class="top-buttons">
          <ion-button
            color="primary"
            @click="$router.go(-1)"
            class="back-button"
          >
            <ion-icon slot="icon-only" :icon="arrowBackIcon"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-buttons v-if="wine" slot="end" class="top-buttons">
          <favorites-button v-if="true" :wine="wine" color="primary" />
          <ion-button v-if="true" mode="ios" @click="share" color="primary">
            <ion-icon slot="icon-only" :icon="shareOutline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
      <div v-if="loading" class="loading-container">
        <ion-spinner></ion-spinner>
      </div>
      <div v-if="loadingError" class="error-container">
        <error-message @retry="fetchCurrentWine" />
      </div>
      <div v-if="!loading && !loadingError && !wine" class="error-container">
        <h1>{{ $t("wine.notFound") }}</h1>
        <ion-button
          router-link="/"
          color="primary"
          fill="outline"
          size="small"
          :strong="true"
          mode="ios"
        >
          {{ $t("wine.goHome") }}
        </ion-button>
      </div>
      <ion-grid fixed class="about-grid">
        <div v-if="wine" class="wrapper">
          <div style="display: flex">
            <div class="wine-details" style="padding: 0">
              <h1 class="wine-title" :class="{ small: wine.name.length > 35 }">
                {{ wine.name }}
              </h1>
              <div class="wine-subtitle">
                <div v-if="wine.rating" class="wine-rating">
                  <div>
                    <template v-if="wine.rating.score">
                      <div class="wine-rating-container">
                        <span class="score">{{
                          wine.rating.score.toFixed(1)
                        }}</span>
                        <rating
                          :rating="wine.rating.score"
                          style="margin-top: 2px"
                        />
                        <wine-rating-hint />
                      </div>
                      <div v-if="wine.rating.ratingsCount" class="rating-count">
                        <span>{{ wine.rating.ratingsCount }}</span>
                        <small>{{
                          $tc("wine.reviewsCount", wine.rating.ratingsCount)
                        }}</small>
                      </div>
                      <small v-else>{{ $t("wine.ratingVivino") }}</small>
                    </template>
                    <template v-else>
                      <small>{{ $t("wine.noRating") }}</small>
                    </template>
                  </div>
                </div>
              </div>
              <div class="badges">
                <div v-for="badge in badges" :key="badge">
                  <div class="badge">
                    {{ badge }}
                  </div>
                </div>
              </div>
            </div>
            <wine-image v-if="wine.imageUrl" :url="wine.imageUrl" />
          </div>

          <div class="wine-details">
            <wine-conclusion :wine="wine" />

            <!-- <wine-temperature v-if="wine.type && wine.group" :wine="wine" /> -->

            <!-- wine-shop can be removed after getWines3 api will ready to show multiple shops, -->
            <wine-shop v-if="wine.price" :wine="wine" />
            <wine-shops v-else :shops="wine.shops" />

            <div class="controls">
              <ion-button
                v-if="!isDesktop && shopGeoLink"
                :href="shopGeoLink"
                target="_blank"
                color="primary"
                expand="block"
                fill="clear"
                :strong="true"
                mode="ios"
              >
                {{ $t("wine.showOnMapButton") }}
              </ion-button>
              <ion-button
                v-if="wine.rating"
                :href="wine.rating.url"
                target="_blank"
                color="primary"
                expand="block"
                fill="clear"
                :strong="true"
                mode="ios"
              >
                {{ $t("wine.readReviewsButton") }}
              </ion-button>
              <ion-button
                v-if="!$router.options.history.state.back"
                @click="goHome"
                color="primary"
                expand="block"
                fill="clear"
                :strong="true"
                mode="ios"
              >
                {{ $t("wine.searchOtherWineButton") }}
              </ion-button>
              <ion-button
                v-if="!isNativeApp && !isDesktop && appStoreLink"
                :href="appStoreLink"
                color="primary"
                expand="block"
                fill="clear"
                :strong="true"
                mode="ios"
              >
                {{ $t("wine.downloadAppButton") }}
              </ion-button>
            </div>
          </div>
        </div>
      </ion-grid>
    </ion-content>
    <age-confirm />
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonGrid,
  IonBackButton,
  IonSpinner,
  isPlatform,
} from "@ionic/vue";
import { arrowBack, shareOutline } from "ionicons/icons";
import { Share } from "@capacitor/share";
import AgeConfirm from "@/components/AgeConfirm.vue";
import FavoritesButton from "@/components/FavoritesButton.vue";
import Rating from "@/components/Rating.vue";
import WineRatingHint from "@/components/WineRatingHint.vue";
import WineImage from "@/components/WineImage.vue";
import WineTemperature from "@/components/WineTemperature.vue";
import WineConclusion from "@/components/WineConclusion.vue";
import WineShop from "@/components/WineShop.vue";
import WineShops from "@/components/WineShops.vue";
import { getAppStoreLink } from "@/lib/url";
import ErrorMessage from "@/components/ErrorMessage.vue";
import {
  logWineShareEvent,
  logWineViewEvent,
  logScreenViewEvent,
  logError,
} from "@/lib/analytics";

export default {
  components: {
    AgeConfirm,
    ErrorMessage,
    FavoritesButton,
    IonPage,
    IonContent,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon,
    IonGrid,
    IonBackButton,
    IonSpinner,
    Rating,
    WineImage,
    WineTemperature,
    WineShop,
    WineShops,
    WineConclusion,
    WineRatingHint,
  },
  data() {
    return {
      arrowBackIcon: arrowBack,
      shareOutline,
      loading: false,
      loadingError: false,
      regionNotAvailable: false,
    };
  },
  created() {
    if (!this.wine && !this.$route.params.id && !this.isDesktop) {
      return this.$router.back();
    }
  },
  async mounted() {
    this.loading = true;
    logScreenViewEvent("wine");

    const { latitude, longitude } = this.$route.query;
    if (latitude && longitude) {
      this.$store.commit("location/setLocation", {
        latitude: parseFloat(latitude),
        longitude: parseFloat(longitude),
      });
    } else {
      await this.$store.dispatch("location/initGeolocation");
    }

    const id = parseInt(this.$route.params.id, 10);
    if (id && (!this.wine || this.wine.id !== id)) {
      this.$store.commit("setCurrentWine", null);
      try {
        await this.$store.dispatch("fetchWineById", id);
      } catch (e) {
        this.loadingError = true;
        logError(e);
      }
    }
    this.loading = false;
  },
  watch: {
    wine(value) {
      logWineViewEvent(value);
    },
  },
  computed: {
    wine() {
      return this.$store.state.currentWine;
    },
    shop() {
      return this.wine.shops && this.wine.shops[0];
    },
    shopGeoLink() {
      if (this.shop) {
        const coords = `${this.shop.location.lat},${this.shop.location.lon}`;
        const query = `${coords}(${this.shop.chain.name})`;

        if (isPlatform("android")) {
          return `geo:${coords}?q=${query}`;
        }
        if (isPlatform("ios")) {
          return `maps:${coords}?q=${query}`;
        }
        return `https://maps.google.com/maps?ll=${coords}&q=${query}`;
      }
      return null;
    },
    isDesktop() {
      return isPlatform("desktop");
    },
    isNativeApp() {
      return isPlatform("capacitor");
    },
    badges() {
      const grapes =
        this.wine?.grapes?.split(",").map((string) => string.trim()) || [];
      return [
        this.wine?.country,
        this.wine.group?.name,
        // eslint-disable-next-line
        ...grapes,
      ].filter(Boolean);
    },
    appStoreLink() {
      return getAppStoreLink();
    },
  },
  methods: {
    goHome() {
      this.$router.push({
        name: "Home",
      });
    },
    fetchCurrentWine() {
      this.$store.dispatch("fetchWineById", this.$route.params.id);
    },
    async share() {
      await Share.share({
        title: this.$t("share.title", { wine: this.wine.name }),
        text: this.$t("share.text", { wine: this.wine.name }),
        url: `https://app.wineradar.ru/wine/${this.wine.id}`,
        dialogTitle: "WINERADAR",
      });
      logWineShareEvent(this.wine);
    },
  },
};
</script>

<style lang="scss" scoped>
.about-header {
  background-color: var(--ion-toolbar-background);
}
.about-grid {
  padding-top: 10px;
  --ion-grid-width: 800px;
}
.about-page-content {
  --padding-top: var(--ion-safe-area-top, 0);
  --background: #fff;
}
ion-toolbar {
  --background: transparent;
  --ion-color-base: transparent !important;
}
.back-button {
  transform: translateX(-5px);
}
.loading-container,
.error-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.wrapper {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.wine-details {
  // min-width: 300px;
  max-width: 450px;
  text-align: left;
  flex: 1;

  .wine-title {
    margin-top: 0;
    padding-left: 5px;
    font-weight: 800;
    font-size: 1.8em;

    &.small {
      font-size: 1.4em;
    }
  }

  .wine-subtitle {
    padding: 0 5px 0 5px;
    display: flex;
    justify-content: space-between;
  }

  .wine-price {
    margin-bottom: 20px;

    div {
      font-size: 1.4em;
    }

    small {
      color: #666;
    }
  }

  .wine-rating {
    margin-bottom: 20px;

    .wine-rating-container {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      margin-bottom: 5px;
    }
    .score {
      padding-right: 5px;
      font-size: 1.4em;
    }
    .rating-count {
      color: #333;

      span {
        padding-right: 5px;
      }
    }
    small {
      font-weight: 500;
      color: #333;
    }
  }

  .wine-properties {
    margin-bottom: 20px;
    padding: 0;
  }

  .badges {
    margin-bottom: 20px;
  }

  .badge {
    border-radius: 25px;
    display: inline-block;
    padding: 10px 20px;
    margin: 0 5px 5px 0;
    font-size: 0.8em;
    background-color: var(--ion-color-light-tint);
  }
}

.controls {
  margin-bottom: 20px;
}
</style>
