<template>
  <ion-card
    v-if="isUpdateAvailable"
    class="ion-padding"
    mode="ios"
    style="margin-top: 10px; margin-bottom: 5px"
    :button="true"
    :href="storeLink"
  >
    <div class="banner">
      <div
        class="display-flex justify-content-space-between align-items-center"
      >
        <h3 class="banner-header">{{ $t("update.title") }}</h3>
        <ion-icon
          :icon="arrowForwardOutline"
          class="flex-shrink-0"
          style="font-size: 20px"
          color="primary"
        />
      </div>
      <p>{{ $t("update.description") }}</p>
    </div>
  </ion-card>
</template>

<script>
import { AppUpdate } from "@capawesome/capacitor-app-update";
import { IonButton, IonCard, IonIcon, isPlatform } from "@ionic/vue";
import { arrowForwardOutline } from "ionicons/icons";
import { getAppStoreLink } from "@/lib/url";

export default {
  components: {
    IonButton,
    IonCard,
    IonIcon,
  },
  data() {
    return {
      arrowForwardOutline,
      isUpdateAvailable: false,
    };
  },
  async mounted() {
    if (!isPlatform("capacitor")) {
      return;
    }
    try {
      const { updateAvailability } = await AppUpdate.getAppUpdateInfo();
      this.isUpdateAvailable = updateAvailability === 2;
    } catch (error) {
      console.error("Error checking for app update", error);
    }
  },
  computed: {
    storeLink() {
      return getAppStoreLink();
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  color: var(--ion-color-dark-tint);

  .banner-header {
    margin: 0;
    font-size: 18px;
    font-weight: 800;
  }

  p {
    color: #666;
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 600px) {
  ion-card {
    width: 350px;
  }
}
</style>
