<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button router-link="/" router-direction="back">
            <ion-icon slot="icon-only" :icon="arrowBackIcon"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title class="ion-text-center">{{
          $t("favorites.title")
        }}</ion-title>
        <ion-buttons slot="end"><div style="width: 48px"></div></ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-content">
      <ion-grid fixed class="page-grid page-grid-content">
        <wine-list
          :wines="visibleWines"
          :is-loading="isLoading"
          :is-error="isError"
          :total="total || 0"
          :display-total="false"
          @fetch="fetchWines"
          @fetch-more="fetchMoreWines"
        >
          <template #empty>
            <div class="hint-icon">
              <img src="@/assets/favorites-empty.svg" style="width: 200px" />
            </div>
            <div class="hint">{{ $t("favorites.hint") }}</div>
          </template>
        </wine-list>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonContent,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonButtons,
  IonButton,
  IonRow,
  IonCol,
  IonIcon,
} from "@ionic/vue";
import { arrowBack } from "ionicons/icons";
import axios from "axios";

import WineList from "@/components/WineList.vue";
import config from "@/app.config";
import {
  logWineListViewEvent,
  logError,
  logScreenViewEvent,
} from "@/lib/analytics";

const CancelToken = axios.CancelToken;

export default {
  components: {
    IonPage,
    IonHeader,
    IonContent,
    IonTitle,
    IonToolbar,
    IonGrid,
    IonButtons,
    IonButton,
    IonRow,
    IonCol,
    IonIcon,
    WineList,
  },
  data() {
    return {
      arrowBackIcon: arrowBack,
      isLoading: false,
      isError: false,
      wines: [],
      page: 1,
      total: 0,
      requestInProgress: false,
    };
  },
  mounted() {
    logScreenViewEvent("favorites");
    this.search();
  },
  computed: {
    visibleWines() {
      return this.wines.filter((wine) => wine.favorite);
    },
  },
  watch: {
    $route(to) {
      if (to.name === "Favorites") {
        this.search();
      }
    },
  },
  methods: {
    async search() {
      this.wines = [];
      this.page = 1;
      this.total = 0;
      this.fetchWines();
    },
    async fetchWines() {
      this.isError = false;
      try {
        this.isLoading = true;
        if (this.requestInProgress) {
          this.requestInProgress.cancel();
        }
        this.requestInProgress = CancelToken.source();

        const searchParams = {
          page: this.page,
          favorites: 1,
          location: {
            Latitude: this.$store.state.location.location.latitude,
            Longitude: this.$store.state.location.location.longitude,
          },
        };
        const { data } = await axios.post(
          `${config.apiHost}/pairing/getwines3.ashx`,
          JSON.stringify(searchParams),
          {
            cancelToken: this.requestInProgress.token,
          },
        );
        if (data.page) {
          this.wines = this.wines.concat(data.page);
          this.total = data.total;
          logWineListViewEvent(this.wines, "Favorites", this.page);
        }
        this.isLoading = false;
      } catch (e) {
        if (!axios.isCancel(e)) {
          this.isError = true;
          this.isLoading = false;
          logError(e);
          throw e;
        }
      }
    },
    async fetchMoreWines(event) {
      this.page++;
      await this.fetchWines();
      event.target.complete();
    },
  },
};
</script>

<style lang="scss" scoped>
.page-grid {
  --ion-grid-width: 800px;
}
.page-grid-content {
  height: 100%;
  padding-top: 10px;
  padding-left: 0;
  padding-right: 0;
}
.centered-container {
  margin-top: calc(50% - 50px);
  text-align: center;
}
.thumbnail {
  display: block;
  height: 70px;
  width: 50px;
  object-fit: contain;
}
.hint-icon ion-icon {
  font-size: 60px;
}
.hint {
  color: var(--ion-color-dark-tint);
  margin: 10px 40px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}
</style>
