<template>
  <ion-row v-if="isError" class="error-container">
    <ion-col>
      <error-message @retry="$emit('fetch')" />
    </ion-col>
  </ion-row>

  <ion-row
    v-if="!isLoading && !isError && wines.length === 0"
    class="error-container"
  >
    <ion-col class="ion-text-center">
      <slot name="empty">
        <img
          src="@/assets/not-found.svg"
          alt="No results"
          style="width: 100px"
        />
        <h3 style="padding: 0 20px; line-height: 22px; font-weight: 700">
          {{ $t("wines.noResults") }}
        </h3>
        <small>{{ $t("wines.noResultsDescription") }}</small>
      </slot>
    </ion-col>
  </ion-row>

  <div class="wine-list">
    <ion-row>
      <ion-col
        size-lg="4"
        size-md="6"
        size-sm="12"
        size-xs="12"
        v-for="(wine, index) in wines"
        :key="wine.id"
        class="wine-card-col"
      >
        <transition appear name="fade-in">
          <wine-card :wine="wine" :style="transitionDelay(index)" />
        </transition>
      </ion-col>
    </ion-row>
    <ion-row class="wine-list wine-list-loader" v-if="isLoading">
      <ion-col
        size-lg="4"
        size-md="6"
        size-sm="12"
        size-xs="12"
        v-for="index in 12"
        :key="index"
      >
        <transition
          appear
          name="fade-in"
          :style="`transition-delay: ${100 * index}ms`"
        >
          <wine-card-skeleton />
        </transition>
      </ion-col>
    </ion-row>
  </div>

  <div style="min-height: 200px">
    <div
      v-if="!isLoading && !isError"
      class="ion-text-center ion-padding"
      style="padding-top: 0"
    >
      <ion-button
        v-show="total - wines.length"
        fill="outline"
        mode="ios"
        shape="round"
        @click="isDesktop ? $emit('fetch-more') : null"
        @touchend="$emit('fetch-more')"
      >
        {{ $t("wines.showMoreButton") }}
      </ion-button>
      <div v-show="total > 0 && displayTotal" class="total-wines">
        <small>{{ $t("wines.totalFound") }} {{ total }}</small>
      </div>
    </div>
  </div>
</template>

<script>
import { IonButton, IonCol, IonRow, isPlatform } from "@ionic/vue";
import WineCard from "@/components/WineCard.vue";
import WineCardSkeleton from "@/components/WineCardSkeleton.vue";
import ErrorMessage from "@/components/ErrorMessage.vue";

const ITEMS_LIMIT = 12;

export default {
  components: {
    IonButton,
    IonCol,
    IonRow,
    WineCard,
    WineCardSkeleton,
    ErrorMessage,
  },
  emits: ["fetch", "fetch-more"],
  props: {
    wines: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    isError: {
      type: Boolean,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    displayTotal: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isDesktop() {
      return isPlatform("desktop");
    },
  },
  methods: {
    transitionDelay(index) {
      const delayTime = 100;
      return { "transition-delay": `${delayTime * (index % ITEMS_LIMIT)}ms` };
    },
  },
};
</script>

<style scoped>
.error-container {
  margin-top: 70px;
}
.total-wines {
  padding: 10px 0;
  opacity: 0.4;
}
</style>
