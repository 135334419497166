<template>
  <div v-if="!locationError && !isLocationBlocked" class="shop-map">
    <AppleMap
      v-if="shop && appleMapIsSupported && isMapkitLoaded"
      :latitude="shop.location.lat"
      :longitude="shop.location.lon"
      :title="shop.chain.name"
      style="height: 300px"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { IonCard, IonCardContent } from "@ionic/vue";
import { Device } from "@capacitor/device";
import AppleMap from "./AppleMap.vue";
import { getMapkit } from "@/lib/geolocationMapkit";

export default {
  components: {
    AppleMap,
    IonCard,
    IonCardContent,
  },
  props: {
    shop: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      appleMapIsSupported: true,
      isMapkitLoaded: false,
    };
  },
  async created() {
    // Failed to init maps on old Androids
    const info = await Device.getInfo();
    if (info.platform === "android" && parseInt(info.osVersion, 10) < 9) {
      this.appleMapIsSupported = false;
    }
    if (this.appleMapIsSupported) {
      const mapkit = await getMapkit();
      this.isMapkitLoaded = !!mapkit;
    }
  },
  computed: {
    ...mapState("location", ["isLocationBlocked", "locationError", "location"]),
  },
};
</script>

<style>
.shop-map canvas {
  border-radius: 10px;
}
</style>
