// Expires 2 Feb 2025 (no domain restriction)
const MAPKIT_TOKEN =
  "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlNGR0daOTI4QzkifQ.eyJpc3MiOiI4NUY5TFc3QThBIiwiaWF0IjoxNzA2OTQyMTc0LCJleHAiOjE3Mzg1NDA4MDB9.p-82Jq4s1hWASQD64W-6U1C9oioA3Y4cmewrRveni5Q9oiNZDNzo_TrSOJWDApmJIOD9kle-xf-D9ldafc9olQ";

let isMapkitInitialized = false;

export async function getAddressByCoords(lat, lon, locale) {
  const mapkit = await getMapkit(locale);
  const geocoder = new mapkit.Geocoder({
    getsUserLocation: false,
    language: locale,
  });
  const coords = new mapkit.Coordinate(lat, lon);

  const geocoderPromise = new Promise((resolve, reject) => {
    geocoder.reverseLookup(coords, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response);
      }
    });
  });

  const response = await geocoderPromise;
  const suggestion = response.results[0];
  return createLocation(suggestion, { latitude: lat, longitude: lon });
}

export async function searchAddress(
  query,
  locale,
  latitude = 0,
  longitude = 0,
) {
  const mapkit = await getMapkit(locale);
  const coordinate = new mapkit.Coordinate(latitude, longitude);
  const search = new mapkit.Search({
    getsUserLocation: false,
    language: locale,
    coordinate,
  });

  const promise = new Promise((resolve, reject) => {
    search.autocomplete(
      query,
      (error, response) => {
        if (error) {
          reject(error);
        } else {
          resolve(response);
        }
      },
      {
        includePointsOfInterest: false,
        includeQueries: false,
      },
    );
  });
  const { results } = await promise;
  return results
    .filter((suggestion) => !!suggestion.coordinate)
    .map((suggestion) => createLocationFromAutocomplete(suggestion));
}

// export async function getLocationByAutocomplete(autocompleteLocation, locale) {
//   const mapkit = await getMapkit(locale);
//   const search = new mapkit.Search({
//     getsUserLocation: false,
//     language: locale,
//   });
//   const query = `${autocompleteLocation.shortAddress} ${autocompleteLocation.city}`;

//   return new Promise((resolve, reject) => {
//     search.search(
//       query,
//       (error, response) => {
//         if (error) {
//           reject(error);
//         } else {
//           resolve(response);
//         }
//       },
//       {
//         includePointsOfInterest: false,
//         includeQueries: false,
//       },
//     );
//   });
// }

export function createLocation(suggestion, coords) {
  const location = {
    fullAddress: "",
    shortAddress: "",
    city: "",
    countryCode: "",
    latitude: coords ? coords.latitude : 55.755826,
    longitude: coords ? coords.longitude : 37.6173,
    isDefault: true,
  };
  if (suggestion) {
    location.isDefault = false;
    location.fullAddress = suggestion.formattedAddress;
    location.shortAddress = suggestion.name;
    location.city = suggestion.administrativeArea || suggestion.locality;
    location.countryCode = suggestion.countryCode;

    if (suggestion.coordinate.latitude && suggestion.coordinate.longitude) {
      location.latitude = suggestion.coordinate.latitude;
      location.longitude = suggestion.coordinate.longitude;
    }
  }
  return location;
}

export function createLocationFromAutocomplete(suggestion) {
  return {
    fullAddress: "",
    shortAddress: suggestion.displayLines[0] || "",
    city: suggestion.displayLines[1] || "",
    countryCode: "",
    latitude: suggestion.coordinate.latitude,
    longitude: suggestion.coordinate.longitude,
    isDefault: false,
  };
}

export function loadMapkit(version = "5.x.x") {
  return new Promise((resolve, reject) => {
    const src = `https://cdn.apple-mapkit.com/mk/${version}/mapkit.js`;

    if (window.mapkit) {
      console.log("already loaded mapkit");
      return resolve(window.mapkit);
    }

    const script = document.createElement("script");
    script.onload = () => resolve(window.mapkit);
    script.onerror = (error) => reject(error);
    document.body.appendChild(script);
    script.setAttribute("crossorigin", "1");
    script.src = src;
  }).then((mapkit) => {
    console.log("mapkit loaded");
    return mapkit;
  });
}

export async function getMapkit(locale) {
  await loadMapkit();

  if (!isMapkitInitialized) {
    window.mapkit.init({
      authorizationCallback: async (done) => {
        const url = "https://app.wineradar.ru/pairing/getMapkitToken.ashx";
        const response = await fetch(url);
        const jsonResponse = await response.json();
        done(jsonResponse.token);
      },
      language: locale,
    });
    isMapkitInitialized = true;
  }
  return window.mapkit;
}
