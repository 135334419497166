<template>
  <filter-popover
    :value="buttonLabel"
    :placeholder="$t('filters.sugar')"
    position="left"
    :apply-button="false"
    @apply="apply"
    ref="popover"
  >
    <ion-radio-group
      v-if="items.length"
      :value="selected"
      mode="md"
      @ionChange="select"
    >
      <ion-item v-for="item in items" :key="item.id" lines="none">
        <ion-label>{{ item.name }}</ion-label>
        <ion-radio slot="start" :value="item.id"></ion-radio>
      </ion-item>
    </ion-radio-group>
  </filter-popover>
</template>

<script>
import { IonRadioGroup, IonRadio, IonItem, IonLabel } from "@ionic/vue";
import FilterPopover from "@/components/FilterPopoverNew.vue";

export default {
  components: {
    IonRadioGroup,
    IonRadio,
    IonItem,
    IonLabel,
    FilterPopover,
  },
  props: {
    value: {
      type: Number,
      required: false,
    },
  },
  mounted() {
    if (this.value) {
      this.selected = this.value;
    }
  },
  watch: {
    value(value) {
      this.selected = value;
    },
  },
  data() {
    return {
      selected: 0,
    };
  },
  methods: {
    select(event) {
      this.selected = parseInt(event.detail.value, 10);
      this.apply();
      this.$refs.popover.closePopover();
    },
    apply() {
      this.$emit("select", this.selected);
    },
    getItemValue(item) {
      return this.value ? this.value.includes(item.id) : false;
    },
  },
  computed: {
    items() {
      const emptyItem = { id: 0, name: this.$t("filters.sugarDefault") };
      const options = this.$store.state.filters.sugarFilters;
      return options ? [emptyItem, ...options] : [];
    },
    buttonLabel() {
      if (this.items.length && this.selected !== 0) {
        return this.items.find((item) => item.id === this.selected).name;
      }
      return null;
    },
  },
};
</script>
