import axios from "axios";

const DADATA_KEY = "2b48f9a43fcf1f43d041e742bad563a8eade5f1c";

export async function getAddressByCoords(lat, lon) {
  const url =
    "https://suggestions.dadata.ru/suggestions/api/4_1/rs/geolocate/address";

  const result = await axios.post(
    url,
    { lat, lon, count: 1 },
    {
      headers: {
        Authorization: "Token " + DADATA_KEY,
      },
    },
  );

  const suggestion = result.data.suggestions[0];
  return suggestion
    ? createLocation(suggestion, { latitude: lat, longitude: lon })
    : null;
}

export async function searchAddress(query) {
  const url =
    "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address";

  const result = await axios.post(
    url,
    { query },
    {
      headers: {
        Authorization: "Token " + DADATA_KEY,
      },
    },
  );
  return result.data.suggestions.map((suggestion) =>
    createLocation(suggestion),
  );
}

export function createLocation(suggestion, coords = null) {
  if (suggestion) {
    const shortAddress = suggestion.value.replace(
      `${suggestion.data.region_with_type}, `,
      "",
    );
    const city = suggestion.data.region_with_type;
    return {
      fullAddress: suggestion.value,
      shortAddress: shortAddress,
      city: city,
      latitude: coords ? coords.latitude : Number.parseFloat(suggestion.data.geo_lat),
      longitude: coords ? coords.longitude : Number.parseFloat(suggestion.data.geo_lon),
      isDefault: false,
    };
  }

  // Default location
  return {
    fullAddress: "",
    shortAddress: "",
    city: "",
    latitude: 55.755826,
    longitude: 37.6173,
    isDefault: true,
  };
}
