import axios from "axios";
import { isPlatform } from "@ionic/vue";
import { setUserPropertyLocation, logError } from "@/lib/analytics";
import {
  getCurrentLocation,
  getAddressByCoords,
  getLocationPermissions,
  getDefaultLocation,
} from "@/lib/geolocation";

const isNativeApp = () => {
  return isPlatform("capacitor");
};

const PERMISSION_DENIED = "denied";
const ERROR_LOCATION_DENIED = 1;

export default {
  namespaced: true,
  state: () => ({
    coordinates: null,
    location: null,
    locationLoading: false,
    locationError: false,
    isLocationBlocked: false,
    isLocationInitialized: false,
    events: [],
  }),
  mutations: {
    setCoordinates(state, coordinates) {
      state.coordinates = coordinates;
    },
    setLocation(state, location) {
      state.location = location;

      axios.defaults.headers.common = {
        ...axios.defaults.headers.common,
        "X-App-Country": location.countryCode,
      };
    },
    setLocationLoading(state, locationLoading) {
      state.locationLoading = locationLoading;
    },
    setLocationError(state, locationError) {
      state.locationError = locationError;
    },
    setLocationBlocked(state) {
      state.isLocationBlocked = true;
    },
    setLocationInitialized(state) {
      state.isLocationInitialized = true;
    },
    addEvent(state, event) {
      state.events.push(event);
    },
  },
  actions: {
    /**
     * Get current coordinates and transform them to address
     */
    async requestGeolocation({ commit, rootState, dispatch }) {
      commit("setLocationLoading", true);

      try {
        // Get HighAccuracy value
        let enableHighAccuracy = localStorage.getItem("enableHighAccuracy");
        if (enableHighAccuracy === null) {
          enableHighAccuracy = true;
          localStorage.setItem("enableHighAccuracy", enableHighAccuracy);
        }

        // Get current coordinates from device
        const { coords } = await getCurrentLocation(enableHighAccuracy);
        commit("setCoordinates", coords);
        commit("addEvent", "coordinates detected");

        // Use geocoder to get address by coordinates
        const location = await getAddressByCoords(
          coords.latitude,
          coords.longitude,
          {
            locale: rootState.locale,
            provider: rootState.config.geolocationProvider,
          },
        );
        if (!location) {
          commit("setLocation", {
            latitude: coords.latitude,
            longitude: coords.longitude,
          });
        } else {
          commit("setLocation", location);
          commit("addEvent", "address detected");
          setUserPropertyLocation(location);
        }
        console.log("Detected location: ", location);

        // dispatch("setCurrencyByCountry", geocoderResult.countryCode, {
        //   root: true,
        // });
      } catch (e) {
        //logError(e);
        console.log('requestGeolocation error');
        commit("addEvent", "location error");
        commit("setLocationError", true);
        if (e.code === ERROR_LOCATION_DENIED) {
          commit("setLocationBlocked");
          commit("addEvent", "location blocked");
        }
        dispatch("setFallbackLocation");
      } finally {
        commit("setLocationLoading", false);
      }
    },
    /**
     * Get place item by autocomplete location object
     */
    async setLocationByAutocomplete({ commit }, location) {
      commit("setLocation", location);
      commit("setLocationInitialized");
    },
    /**
     * Entry point
     */
    async checkGeolocation({ dispatch, commit }) {
      if (isNativeApp()) {
        const permissions = await getLocationPermissions();
        if (permissions.location === PERMISSION_DENIED) {
          console.log("permissions: ", permissions.location);
          commit("setLocationBlocked");
          dispatch("setFallbackLocation");
        }
      }
      await dispatch("requestGeolocation");
    },
    /**
     * Initialize geolocation once
     */
    async initGeolocation({ state, commit, dispatch }) {
      if (!state.isLocationInitialized) {
        await dispatch("checkGeolocation");
        commit("setLocationInitialized");
      }
    },
    /**
     * Set fallback location
     */
    async setFallbackLocation({ commit }) {
      commit("setLocation", getDefaultLocation());
    },
  },
};
