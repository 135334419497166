<template>
  <ion-card
    v-if="isMobileWeb"
    class="ion-padding outlined"
    mode="ios"
    :button="true"
    :href="storeLink"
  >
    <div
      class="display-flex align-items-center"
      style="justify-content: space-between"
    >
      <div class="banner-text">
        <b style="font-size: 15px">Скачайте наше приложение</b><br />
        <img
          src="@/assets/google-play-badge.png"
          style="width: 150px; margin-top: 10px"
        />
      </div>
      <ion-icon :icon="arrowForwardOutline" size="large" />
    </div>
  </ion-card>
</template>

<script>
import { IonCard, isPlatform, IonIcon } from "@ionic/vue";
import { getAppStoreLink } from "@/lib/url";
import { arrowForwardOutline } from "ionicons/icons";

export default {
  components: {
    IonCard,
    IonIcon,
  },
  data() {
    return {
      arrowForwardOutline,
    };
  },
  computed: {
    isMobileWeb() {
      return isPlatform("mobileweb") && isPlatform("android");
    },
    storeLink() {
      return getAppStoreLink();
    },
  },
};
</script>
