<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button router-link="/" router-direction="back">
            <ion-icon slot="icon-only" :icon="arrowBackIcon"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="centered-container">
        <h1
          class="brand-logo ion-padding prevent-select"
          style="font-size: 40px"
          @click="onLogoClick"
        >
          WINERADAR
        </h1>
        <div v-if="isNativeApp" class="muted">
          Версия {{ $store.state.appVersion }}
        </div>
        <div class="muted">
          <div>
            {{ deviceInfo.model }} {{ deviceInfo.operatingSystem }}
            {{ deviceInfo.osVersion }}
          </div>
          <span v-if="deviceInfo.androidSDKVersion"
            >Android SDK {{ deviceInfo.androidSDKVersion }}</span
          >
        </div>

        <debug-settings v-if="showDebug" />
      </div>

      <ion-grid style="margin-top: 50px">
        <ion-row>
          <ion-col size-sm="12">
            <ion-card
              href="https://t.me/wineradarru"
              mode="ios"
              class="contact-card"
            >
              <ion-card-content class="display-flex align-items-center">
                <div>
                  <h2>Связаться с нами</h2>
                  <p>
                    Если вы нашли какую либо проблему, сообщите нам в Telegram
                  </p>
                  <div class="telegram-handle">
                    <ion-button
                      color="dark"
                      fill="outline"
                      class="telegram-button"
                    >
                      <img
                        src="@/assets/telegram.svg"
                        alt="Telegram logo"
                        class="ion-padding-end"
                      />
                      @wineradarru
                    </ion-button>
                  </div>
                </div>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonContent,
  IonToolbar,
  IonButton,
  IonButtons,
  IonIcon,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonCardTitle,
  IonGrid,
  IonRow,
  IonCol,
  isPlatform,
} from "@ionic/vue";
import { arrowBack } from "ionicons/icons";
import { Device } from "@capacitor/device";
import DebugSettings from "@/components/DebugSettings.vue";

export default {
  components: {
    IonPage,
    IonHeader,
    IonContent,
    IonToolbar,
    IonButton,
    IonButtons,
    IonIcon,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonCardTitle,
    IonGrid,
    IonRow,
    IonCol,
    DebugSettings,
  },
  data() {
    return {
      arrowBackIcon: arrowBack,
      clickCount: 0,
      showDebug: false,
      clickTimeout: null,
      deviceInfo: () => {},
    };
  },
  async created() {
    this.deviceInfo = await Device.getInfo();
  },
  computed: {
    isNativeApp() {
      return isPlatform("capacitor");
    },
  },
  methods: {
    onLogoClick() {
      if (!this.clickTimeout) {
        this.clickTimeout = setTimeout(() => {
          this.clickCount = 0;
          clearTimeout(this.clickTimeout);
          this.clickTimeout = null;
        }, 2000);
      }

      this.clickCount++;

      if (this.clickCount >= 5) {
        this.showDebug = true;
      }
    },
  },
};
</script>

<style scoped>
.centered-container {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.telegram-button {
  font-size: 16px;
  font-weight: 700;
  --padding-start: 7px;
  border-radius: 20px;
}
h2 {
  font-weight: 800;
  font-size: 1.5em;
  color: var(--ion-color-dark);
  padding-bottom: 5px;
}
.telegram-handle {
  padding-top: 5px;
  font-weight: 700;
}
ion-card {
  max-width: 500px;
  margin-bottom: 10px;
}
.prevent-select {
  -webkit-user-select: none; /* Safari */
  user-select: none; /* Standard syntax */
}
</style>
