<template>
  <div>
    <div id="cameraPreview"></div>
    <div v-show="!isLoading" class="camera-frame">
      <div class="corner top-left"></div>
      <div class="corner top-right"></div>
      <div class="corner bottom-left"></div>
      <div class="corner bottom-right"></div>
    </div>
    <ion-fab vertical="bottom" horizontal="center" slot="fixed">
      <ion-fab-button v-if="!isLoading" @click="takePhoto" color="light">
        <div class="take-photo-button"></div>
      </ion-fab-button>
    </ion-fab>
  </div>
</template>

<script>
import { IonFab, IonFabButton, IonIcon } from "@ionic/vue";
import { cameraOutline } from "ionicons/icons";
import { CameraPreview } from "@capacitor-community/camera-preview";

export default {
  components: {
    IonFab,
    IonFabButton,
    IonIcon,
  },
  emits: ["photo"],
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cameraIcon: cameraOutline,
    };
  },
  methods: {
    startCamera() {
      const cameraPreviewOptions = {
        position: "rear",
        parent: "cameraPreview",
        toBack: true,
        className: "camera-preview-video",
        rotateWhenOrientationChanged: false,
        disableAudio: true,
        width: window.innerWidth,
        height: window.innerHeight,
      };

      document.body.style.backgroundColor = "transparent";
      return CameraPreview.start(cameraPreviewOptions);
    },
    stopCamera() {
      document.body.style.removeProperty("background-color");
      return CameraPreview.stop();
    },
    async takePhoto() {
      const photo = await CameraPreview.capture({
        quality: 100,
        width: window.innerWidth,
        height: window.innerHeight,
      });
      const photoUrl = `data:image/jpeg;base64,${photo.value}`;

      this.$emit("photo", photoUrl);
    },
  },
};
</script>

<style scoped>
ion-fab {
  margin-bottom: calc(var(--ion-safe-area-bottom, 0) + 10px);
}
.spinner-container {
  /* background-color: rgba(0, 0, 0, 0.5); */
  padding: 10px;
  border-radius: 10px;
}
ion-spinner {
  color: #fff;
}
.take-photo-button {
  border: 3px #000 solid;
  width: calc(100% - 3px);
  height: calc(100% - 3px);
  border-radius: 50%;
}
.camera-frame {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  height: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;

  .corner {
    position: absolute;
    width: 60px;
    height: 60px;
    border: 5px solid #fff;
    border-radius: 30px;
  }

  .top-left {
    top: 0;
    left: 0;
    border-bottom: none;
    border-right: none;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }

  .top-right {
    top: 0;
    right: 0;
    border-bottom: none;
    border-left: none;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
  }

  .bottom-left {
    bottom: 0;
    left: 0;
    border-top: none;
    border-right: none;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .bottom-right {
    bottom: 0;
    right: 0;
    border-top: none;
    border-left: none;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
