<template>
  <div style="background-color: #fff" class="ion-padding">
    <div class="display-flex align-items-center">
      <ion-toggle :checked="useDaData" @ion-change="toggleUseDaData" /><small
        >Use DaData API</small
      >
    </div>
    <div class="display-flex align-items-center">
      <ion-toggle
        :checked="showGeolocationEvents"
        @ion-change="toggleShowGeolocationEvents"
      /><small>Show geolocation events</small>
    </div>
    <div class="display-flex align-items-center">
      <ion-toggle
        :checked="enableHighAccuracy"
        @ion-change="toggleEnableHighAccuracy"
      /><small>High accuracy geolocation</small>
    </div>
  </div>
</template>

<script>
import { IonToggle } from "@ionic/vue";

export default {
  name: "DebugSettings",
  components: {
    IonToggle,
  },
  data() {
    return {
      useDaData: true,
      showGeolocationEvents: false,
      enableHighAccuracy: false,
    };
  },
  created() {
    const { geolocationProvider } = this.$store.state.config;
    this.useDaData = !geolocationProvider || geolocationProvider === "dadata";
    this.showGeolocationEvents =
      localStorage.getItem("showGeolocationEvents") === "true";
    this.enableHighAccuracy =
      localStorage.getItem("enableHighAccuracy") === "true";
  },
  methods: {
    toggleUseDaData() {
      this.useDaData = !this.useDaData;
      const geolocationProvider = this.useDaData ? "dadata" : "mapkit";
      const config = this.$store.state.config;
      this.$store.commit("setConfig", { ...config, geolocationProvider });
    },
    toggleShowGeolocationEvents() {
      this.showGeolocationEvents = !this.showGeolocationEvents;
      localStorage.setItem(
        "showGeolocationEvents",
        this.showGeolocationEvents ? "true" : "false",
      );
    },
    toggleEnableHighAccuracy() {
      this.enableHighAccuracy = !this.enableHighAccuracy;
      localStorage.setItem(
        "enableHighAccuracy",
        this.enableHighAccuracy ? "true" : "false",
      );
    },
  },
};
</script>
