<template>
  <ion-button mode="ios" @click="toggleFavorites">
    <ion-icon slot="icon-only" :icon="icon"></ion-icon>
  </ion-button>
</template>

<script>
import { heart, heartOutline, heartDislike } from "ionicons/icons";
import { IonButton, IonIcon, toastController } from "@ionic/vue";
import {
  toggleFavoriteWine,
  toggleFavoriteWineLegacyId,
} from "@/lib/favorites";

export default {
  components: {
    IonButton,
    IonIcon,
  },
  props: {
    wine: {
      type: Object,
      required: true,
    },
  },
  computed: {
    icon() {
      return this.isFavorite ? heart : heartOutline;
    },
    isFavorite() {
      return this.wine.favorite;
    },
  },
  methods: {
    async toggleFavorites() {
      this.wine.favorite = !this.wine.favorite;

      if (this.wine.distance) {
        // Remove this after migration to getWines4
        await toggleFavoriteWineLegacyId(this.wine.id);
      } else {
        await toggleFavoriteWine(this.wine.id);
      }

      const toast = await toastController.create({
        color: "dark",
        cssClass: "rate-toast ion-text-left",
        duration: 2000,
        header: "",
        message: this.isFavorite
          ? this.$t("favorites.addedMessage")
          : this.$t("favorites.removedMessage"),
        position: "bottom",
        icon: this.isFavorite ? heart : heartDislike,
      });
      return toast.present();
    },
  },
};
</script>
